import axios from 'axios';
import { trackAddToCart, trackRemoveFromCart } from '../../dataLayer';

const fetchCartUrl = process.env.VUE_APP_API_WHOLESALE + '/api/user/cart';
const updateCartUrl = process.env.VUE_APP_API_WHOLESALE + '/api/user/cart';
const updateCartItemUrl = process.env.VUE_APP_API_WHOLESALE + '/api/user/cart/item';
const emptyCartUrl = process.env.VUE_APP_API_WHOLESALE + '/api/user/cart/delete';

function roundPrice (price) {
    return Math.round(price * 100) / 100;
}

function isPinned (id) {
    try {
        let previouslyPinned = localStorage.pinned === undefined ? [] : JSON.parse(localStorage.pinned);

        return previouslyPinned.indexOf(id) !== -1 ? true : false;
    }
    catch (error) {
        return false;
    }
}

function savePinnedToLocalStorage (product) {
    let previouslyPinned = localStorage.pinned === undefined ? [] : JSON.parse(localStorage.pinned);

    if (product.pinned) {
        previouslyPinned.push(product.id);
    } else {
        let index = previouslyPinned.indexOf(product.id);
        previouslyPinned.splice(index, 1);
    }

    localStorage.pinned = JSON.stringify(previouslyPinned);
}

function getCartFromLocalStorage () {
    try {
        if (!localStorage.cart) return [];

        return JSON.parse(localStorage.cart);
    } catch (error) {
        return [];
    }
}

function subitemStockLimit(product) {
    let subitem_stock_limit = null;

    if (product.require_subitem_quantities) {
        let limiting_stock = null;

        product.subitems.forEach(subitem => {
            if (subitem.qty > 0 && subitem.subitem_available !== null) {
                let limited_stock = Math.floor(subitem.subitem_available / subitem.qty);

                if (limiting_stock == null || limited_stock < limiting_stock) {
                    limiting_stock = limited_stock;
                }
            }
        });

        if (limiting_stock !== null) {
            subitem_stock_limit = limiting_stock;
        }
    }

    return subitem_stock_limit;
}

function productStock(product) {
    let product_stock = product.available;

    if (product.allow_oversell) {
        return MAX_QTY;
    }

    let subitem_stock_limit = subitemStockLimit(product);
    if (subitem_stock_limit !== null) {
        product_stock = subitem_stock_limit;
    }

    return product_stock;
}

const MAX_QTY = 999;

const state = () => ({
    all: [],
    favourites: [],
    searchTerm: '',
    foodFilters: [],
    promoFilters: [],
    brandFilters: [],
    promo: {
        code: null,
        amount: null,
        type: null,
    },
    shipping: {
        method: 'pickup',
        fee: 0,
        freeAvailable: false,
        pickupLocation: 0, // Default PE Woodstock
    },
});

const getters = {
    products (state) {
        return state.all;
    },

    product: (state) => (slug) => {
        return state.all.find(p => p.slug === slug);
    },

    productsInCart (state) {
        return state.all.filter(p => p.quantity > 0 && p.active );
    },

    favourites: (state) => {
        return state.all.filter(p => state.favourites.includes(p.id));
    },

    cartCount (state, getters) {
        const cartCount = getters.productsInCart.reduce((carry, p) => {
            return carry += p.quantity
        }, 0);

        return cartCount <= 99 ? cartCount : '99+';
    },

    subtotal (state, getters) {
        var subtotal = 0;

        getters.productsInCart.forEach(product => {
            if (product.active) {
                if(product.price_2plus && product.quantity >= 2) subtotal += (product.quantity * product.price_2plus);
                else subtotal += (product.quantity * product.price);
            }
        });

        return roundPrice(subtotal);
    },

    discount (state, getters) {
        let amount = 0;

        if (state.promo.type === 'prc_discount') {
            amount = Math.round((getters.total * 100) * (state.promo.amount / 100)) / 100;
        } else {
            amount = state.promo.amount;
        }

        return amount;
    },

    tax (state, getters) {
        var tax = 0;

        getters.productsInCart.forEach(product => {
            if (!product.taxable) return;

            const price = product.quantity >= 2 && product.price_2plus !== null ? product.price_2plus : product.price;

            tax += product.quantity * price * 0.13;

        });

        if (state.shipping.method === 'delivery') {
            tax += getters.delivery * 0.13;
        }

        return roundPrice(tax);
    },

    delivery (state, getters) {
        if (state.shipping.method === 'delivery' && (state.shipping.freeAvailable == 0 || getters.subtotal < 85)) {
            return state.shipping.fee;
        }

        return 0;
    },

    promo (state) {
        return state.promo;
    },

    total (state, getters) {
        let total = getters.subtotal + getters.tax + getters.delivery;

        return roundPrice(total);
    },

    grandTotal (state, getters) {
        let total = getters.total - getters.discount;

        if (total < 0) {
            total = 0;
        }

        return roundPrice(total);
    },
};

const actions = {
    fetchProducts({ commit }) {
        return axios
            .get(process.env.VUE_APP_API_WHOLESALE + '/api/products')
            .then(response => {
                commit('setProducts', response.data)
            });
    },

    fetchCart({ commit, rootState }) {
        let currentUser = rootState.user.currentUser;

        commit('emptyCart');

        if (currentUser) {
            axios.get(fetchCartUrl).then(response => {
                if (response.status === 200) {
                    commit('setCart', response.data);
                }
            });
        } else {
            commit('setCart', getCartFromLocalStorage());
        }
    },

    fetchFavourites ({ commit }) {
        axios.get(process.env.VUE_APP_API_WHOLESALE + '/api/user/favorites').then(response => {
            if (response.status === 200) {
                commit('setFavourites', response.data);
            }
        });
    },

    saveUserCart({ rootState, getters }) {
        let currentUser = rootState.user.currentUser;

        if (currentUser) {
            axios.post(updateCartUrl, { items: getters.productsInCart });
        }
    },

    updateCartItem({ commit, rootState, getters }, payload) {
        let currentUser = rootState.user.currentUser;

        const product = getters.products.find(p => p.id === payload.id);
        const quantityDifference = payload.qty - product.quantity;

        commit('updateProductQuantity', payload);
        let price = payload.price;

        if (payload.qty >= 2 && product.price_2plus > 0) {
            price = product.price_2plus;
        }

        if (currentUser) {
            axios.post(updateCartItemUrl, {
                product_id: payload.id,
                quantity: payload.qty,
                price: price
            });
        } else {
            commit('saveCartToLocalStorage');
        }

        if (quantityDifference > 0) {
            trackAddToCart(product, quantityDifference);
        } else if (quantityDifference < 0) {
            trackRemoveFromCart(product, quantityDifference);
        }
    },

    removeItemFromCart({ commit, rootState, getters }, payload) {
        let currentUser = rootState.user.currentUser;

        const product = getters.products.find(p => p.id === payload);
        const quantityToRemove = product.quantity;

        commit('removeFromCart', payload);

        if (currentUser) {
            axios.post(updateCartItemUrl, { product_id: payload, quantity: 0 });
        } else {
            commit('saveCartToLocalStorage');
        }

        trackRemoveFromCart(product, quantityToRemove);
    },

    emptyCart({commit, rootState}) {
        let currentUser = rootState.user.currentUser;

        commit('emptyCart');

        if (currentUser) {
            axios.post(emptyCartUrl);
        } else {
            commit('saveCartToLocalStorage');
        }
    },
};

const mutations = {
    setProducts (state, payload) {
        state.all = payload.map(item => {
            return {
                id: item.id,
                category_id: item.category_id,
                category: item?.category ?? null,
                title: item.name,
                price: item.price,
                price_2plus: item.price_2plus,
                stock: item.stock,
                max_order_qty: item.max_order_qty,
                require_subitem_quantities: item.require_subitem_quantities,
                quantity: 0,
                size: item.unit,
                description: item.description,
                long_description: item.long_description,
                custom_sold_out_message: item.custom_sold_out_message,
                contents: item.contents,
                image: item.image ? process.env.VUE_APP_API_WHOLESALE + '/storage/' + item.image : '',
                images: item.images.map(i => {
                    return {
                        id: i.id,
                        item_id: i.product_id,
                        filename: process.env.VUE_APP_API_WHOLESALE + '/storage/' + i.filename,
                    }
                }),
                gift_card: item.gift_card,
                locally_grown: item.locally_grown,
                special: item.special,
                new: item.new,
                organic: item.organic,
                taxable: item.taxable,
                active: item.active,
                pinned: isPinned(item.id),
                slug: item.slug,
                long_description_no_tags: item.long_description_no_tags,
                brand: item.brand,
                store_excludes: item.store_excludes,
                subitems: item.subitems,
                allow_oversell: item.allow_oversell,
                available: item.available,
            }
        });
    },

    updateProducts (state, payload) {
        payload.forEach(updatedProduct => {
            let product = state.all.find(p => updatedProduct.id === p.id)

            if (product)
            {
                product.active = updatedProduct.active;
                product.price = updatedProduct.price;
                product.size = updatedProduct.unit;
                product.stock = updatedProduct.stock;
                product.allow_oversell = updatedProduct.allow_oversell;
                product.available = updatedProduct.available;
                product.max_order_qty = updatedProduct.max_order_qty;
                product.store_excludes = updatedProduct.store_excludes;
                product.require_subitem_quantities = updatedProduct.require_subitem_quantities;
                product.subitems = updatedProduct.subitems;

                // Verify current quantity with new stock/available amounts
                let data = {
                    id: product.id,
                    qty: product.quantity,
                }

                this.commit('updateProductQuantity', data);
            }
        });
    },

    setSearchTerm(state, payload) {
        state.searchTerm = payload;
    },

    setFoodFilters(state, payload) {
        state.foodFilters = payload;
    },

    setPromoFilters(state, payload) {
        state.promoFilters = payload;
    },

    setBrandFilters(state, payload) {
        state.brandFilters = payload;
    },

    setPromoCode(state, payload) {
        state.promo = payload;
    },

    setShipping(state, payload) {
        state.shipping = payload;
    },

    setShippingMethod(state, payload) {
        state.shipping.method = payload;
    },

    setPickupLocation(state, payload) {
        state.shipping.pickupLocation = payload;
    },

    updateProductQuantity(state, payload) {
        let product = state.all.find(p => p.id === payload.id);

        product.quantity = parseInt(payload.qty);
        let product_stock = productStock(product);

        if (isNaN(product.quantity) || product.quantity < 0) {
            product.quantity = 0;
        } else if (product_stock !== null && product.quantity > product_stock) {
            product.quantity = parseInt(product_stock);
        } else if (product.max_order_qty !== null && product.quantity > product.max_order_qty) {
            product.quantity = parseInt(product.max_order_qty);
        } else if (product.quantity > MAX_QTY) {
            product.quantity = MAX_QTY;
        }
    },

    removeFromCart (state, payload) {
        let product = state.all.find(p => p.id === payload);

        product.quantity = 0;
    },

    setCart (state, payload) {
        if (payload.length > 0) {
            payload.forEach(item => {
                let product = state.all.find(p => p.id === item.product_id);

                if (product === undefined) return;

                product.quantity = item.quantity;
            });
        }
    },

    setFavourites (state, payload) {
        state.favourites = payload;
    },

    emptyCart (state) {
        state.all.forEach(p => {
            p.quantity = 0;
        });
    },

    saveCartToLocalStorage (state) {
        try {
            localStorage.cart = JSON.stringify(state.all.filter(p => p.quantity > 0).map(p => {
                return {
                    product_id: p.id,
                    quantity: p.quantity,
                };
            }));
        } catch (error) {
            return false;
        }
    },

    resetShipping (state) {
        state.shipping = {
            method: 'pickup',
            fee: null,
            freeAvailable: false,
        }
    },

    resetPromo (state) {
        state.promo = {
            code: null,
            amount: null,
            type: null,
        }
    },

    togglePin (state, payload) {
        let product = state.all.find(p => p.id === payload.id);

        product.pinned = payload.pinned;

        savePinnedToLocalStorage(product);
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
