<template>
    <div id="app" :class="$route.name ? 'page-' + $route.name.toLowerCase() : ''">
        <div class="header sticky-top" id="header">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" id="navbar">
                <router-link v-if="!isHomePage" class="navbar-brand" :to="{ name: 'home' }">
                    <img src="/images/logo_s.png" alt="Produce Express" class="logo" width="180px" height="auto" />
                </router-link>

                <a v-else href="#" class="navbar-brand" @click.prevent="handleScrollToTop">
                    <img src="/images/logo_s.png" alt="Produce Express" class="logo" width="180px" height="auto" />
                </a>

                <div class="d-lg-none d-flex align-items-center">
                    <div v-if="currentUser === null" class="user-account user-account-mobile">
                        <a href="#" @click.prevent="showModal('login')">
                            <img src="/images/icons/user.svg" width="26px" height="26px" class="icon" alt="User" style="margin-top: -3px"/>
                            <img src="/images/icons/user-solid.svg" width="26px" height="26px" class="icon-solid" alt="User" style="margin-top: -3px" />
                            <span class="pl-1 d-inline-block">Login</span>
                        </a>
                    </div>

                    <div v-else class="user-account user-account-mobile signed-in dropdown">
                        <user-menu :currentUser="currentUser" />
                    </div>

                    <div class="menuCart mr-3" :class="{ 'mr-4': cartCount }">
                        <router-link :to="{ name: 'cart' }">
                            <span class="menuCartCount" v-if="cartCount">{{ cartCount }}</span>
                        </router-link>
                    </div>

                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" ref="navButton">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </div>

                <div class="collapse navbar-collapse" id="navbarSupportedContent" ref="hamburgerMenu">
                    <ul class="navbar-nav ml-auto">
                        <li><a href="#" @click.stop.prevent="showModal('delivery-check')" data-toggle="collapse" data-target=".navbar-collapse">Delivery Check</a></li>
                        <li><a href="#" @click.stop.prevent="showModal('about')" data-toggle="collapse" data-target=".navbar-collapse">About</a></li>
                        <li><a href="#" @click.stop.prevent="showModal('how-it-works')" data-toggle="collapse" data-target=".navbar-collapse">How It Works</a></li>
                        <li><a href="#" @click.stop.prevent="showModal('contact')" data-toggle="collapse" data-target=".navbar-collapse">Contact</a></li>

                        <li v-if="currentUser === null" class="user-account user-account-desktop">
                            <a href="#" @click.prevent="showModal('login')">
                                <img src="/images/icons/user.svg" width="26px" height="26px" class="icon" alt="User" style="margin-top: -3px"/>
                                <img src="/images/icons/user-solid.svg" width="26px" height="26px" class="icon-solid" alt="User" style="margin-top: -3px" />
                                <span class="pl-1 d-inline-block">Login</span>
                            </a>
                        </li>

                        <li v-else class="user-account user-account-desktop signed-in dropdown">
                            <user-menu :currentUser="currentUser" />
                        </li>

                        <li class="d-none d-lg-block pr-2">
                            <div class="menuCart">
                                <router-link :to="{ name: 'cart' }">
                                    <span class="menuCartCount" v-if="cartCount">{{ cartCount }}</span>
                                </router-link>
                            </div>
                        </li>
                    </ul>

                    <div class="selling-point-container">
                        <div class="selling-point p-0 selling-point-overlap">
                            <img src="/images/free-delivery.png?v=3"/>
                        </div>

                        <div class="selling-point p-0 selling-point-overlap clickable" @click.stop.prevent="showModal('referral')">
                            <img src="/images/referral-program.png?v=2"/>
                        </div>

                        <div class="selling-point p-0">
                            <img src="/images/fresher-products.png?v=2"/>
                        </div>
                    </div>
                </div>
            </nav>

            <!-- medium mobile screen 3 icons -->
            <div class="container-lg py-1 d-lg-none bg-white hide-if-sm selling-point-container-mobile" v-show="showMobileSellingPoints">
                <div class="row d-lg-none justify-content-end" style="padding-right: 15px;">

                    <div class="px-0 selling-point-overlap col-4">
                        <img src="/images/free-delivery.png?v=3"/>
                    </div>

                    <div class="px-0 selling-point-overlap col-4" @click.stop.prevent="showModal('referral')">
                        <img src="/images/referral-program.png?v=2"/>
                    </div>

                    <div class="px-0 col-4" @click.stop.prevent="showModal('referral')">
                        <img src="/images/fresher-products.png?v=2"/>
                    </div>

                </div>
            </div>

            <!-- small mobile screen 2 icons -->
            <div class="container-lg py-1 d-lg-none bg-white hide-if-lg selling-point-container-mobile" v-show="showMobileSellingPoints">
                <div class="row d-lg-none justify-content-end align-items-end">

                    <div class="pr-0 selling-point-overlap col-6">
                        <img src="/images/free-delivery-mobile.png?v=3" />
                    </div>

                    <div class="pl-0 col-6" @click.stop.prevent="showModal('referral')">
                        <img src="/images/referral-program-mobile.png?v=2"   />
                    </div>

                </div>
            </div>

        </div>

        <div id="main">
            <keep-alive :include="['Product', 'Home', 'Cart', 'Checkout']" v-if="!loading">
                <router-view
                    :thankyou_email="thankyou_email"
                    @order-complete="handleOrderComplete"
                    @login="handleLoginToggle"
                />
            </keep-alive>

            <loading v-else />
        </div>

        <modal-delivery-check
            :show="showModalDeliveryCheck"
            @close="handleCloseModal()"
        ></modal-delivery-check>

        <modal-delivery-times
            :show="showModalDeliveryTimes"
            @close="closeDeliveryTimesModal($event)"
        ></modal-delivery-times>

        <modal-about
            :show="showModalAbout"
            @close="handleCloseModal()"
        ></modal-about>

        <modal-how-it-works
            :show="showModalHow"
            @close="handleCloseModal()"
        ></modal-how-it-works>

        <modal-contact
            :show="showModalContact"
            @close="handleCloseModal()"
        ></modal-contact>

        <modal-referral
            :show="showModalReferral"
            @close="handleCloseModal()"
        ></modal-referral>

        <modal-sunday
            :show="showModalSunday"
        />

        <modal-login
            :show="showModalLogin"
            :login-message="loginMessage"
            @close="handleCloseModal()"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Loading from '@/components/Loading'
import ModalDeliveryCheck from './components/ModalDeliveryCheck.vue';
import ModalDeliveryTimes from './components/ModalDeliveryTimes.vue';
import ModalAbout from './components/ModalAbout.vue';
import ModalHowItWorks from './components/ModalHowItWorks.vue';
import ModalContact from './components/ModalContact.vue';
import ModalReferral from './components/ModalReferral.vue';
import ModalSunday from './components/ModalSunday.vue';
import ModalLogin from './components/ModalLogin.vue';
import UserMenu from '@/components/UserMenu.vue';

export default {
    components: {
        Loading,
        ModalDeliveryCheck,
        ModalDeliveryTimes,
        ModalAbout,
        ModalHowItWorks,
        ModalContact,
        ModalReferral,
        ModalSunday,
        ModalLogin,
        UserMenu,
    },

    data () {
        return {
            loadingProducts: true,
            loadingAnnouncements: true,
            showModalDeliveryCheck: false,
            showModalAbout: false,
            showModalHow: false,
            showModalContact: false,
            showModalReferral: false,
            showModalSunday: this.isSunday(),
            showModalLogin: false,
            thankyou_email: null,
            loginMessage: '',
        }
    },

    computed: {
        ...mapState({
            currentUser: state => state.user.currentUser,
            showModalDeliveryTimes: state => state.showModalDeliveryTimes,
        }),

        ...mapGetters([
            'cartCount',
        ]),

        loading () {
            return this.loadingProducts || this.loadingAnnouncements;
        },

        showMobileSellingPoints () {
            if (this.$route.name === 'Product') {
                return false;
            }

            return true;
        },

        isHomePage () {
            return this.$route.name === 'home';
        },

        usersName () {
            return this.currentUser.first_name + ' ' + this.currentUser.last_name;
        },
    },

    methods: {
        ...mapActions([
            'setShowModalDeliveryTimes',
        ]),

        isSunday () {
            return (new Date().getDay()) === 0;
        },

        showModal (type) {
            this.showModalDeliveryCheck = type === 'delivery-check';
            this.showModalAbout = type === 'about';
            this.showModalHow = type === 'how-it-works';
            this.showModalContact = type === 'contact';
            this.showModalLogin = type === 'login';
            this.showModalReferral = type === 'referral';

            document.getElementById('navbarSupportedContent').classList.remove('show');

            this.$router.push({ name: type });
        },

        handleOrderComplete (payload) {
            this.thankyou_email = payload.email;
        },

        handleLoginToggle (payload) {
            this.loginMessage = payload;
            this.showModal('login');
        },

        handleCloseModal () {
            this.showModalDeliveryCheck = false;
            this.showModalAbout = false;
            this.showModalHow = false;
            this.showModalContact = false;
            this.showModalReferral = false;
            this.showModalLogin = false;

            this.$router.push('/');
        },

        handleScrollToTop () {
            window.scroll(0, 0);
        },

        handleScrolling () {
            if (this.$refs?.hamburgerMenu?.classList) {
                this.$refs.hamburgerMenu.classList.remove('show');
            }
        },

        closeDeliveryTimesModal(closeOnForever = false) {
            this.setShowModalDeliveryTimes(false);

            if (closeOnForever) {
                this.$cookies.set("delivery_times_modal", 10, -1);
            }
        },
    },

    created () {
        this.$store.dispatch("fetchCategories");

        this.$store.dispatch("fetchProducts").then(() => {
            this.$store.dispatch("fetchCart");

            //once done loading products, wait 4 seconds and display referral modal, if cookie not set
            setTimeout(() => {
                if (!this.$cookies.get("referral_displayed") && process.env.VUE_APP_SHOW_MODAL_REFERRAL === 'true') {
                    //show the referral modal and set the cookie
                    this.showModal('referral');
                    this.$cookies.set("referral_displayed", true, -1, null, null, null, "Lax");//never expires
                }
            }, 4000);
        }).finally(() => {
            this.loadingProducts =  false;
        });

        this.$store.dispatch("fetchAnnouncements").finally(() => this.loadingAnnouncements =  false);

        window.addEventListener("scroll", function() {
            var banner = document.getElementById("bannerControls");
            var header = document.getElementById('header');
            var offset = banner !== null ?
                banner.offsetHeight - 1: // -1 to prevent jumpy scrolling when body is too short
                header.offsetHeight;

            if (window.scrollY >= offset) {
                header.classList.add('stickied');
                document.body.classList.add('stickied');
            } else {
                header.classList.remove('stickied');
                document.body.classList.remove('stickied');
            }
        });
    },

    mounted () {
        if (this.$route.hash == '#referral' || this.$route.name === 'referral') {
            this.showModalReferral = process.env.VUE_APP_SHOW_MODAL_REFERRAL === 'true';
        }

        switch (this.$route.name) {
            case 'delivery-check':
                this.showModalDeliveryCheck = true;
                break;

            case 'about':
                this.showModalAbout = true;
                break;

            case 'how-it-works':
                this.showModalHow = true;
                break;

            case 'contact':
                this.showModalContact = true;
                break;

            case 'login':
                this.showModalLogin = true;
                break;

            case 'register':
                this.showModalLogin = true;
                break;
        }

        this.observer = new MutationObserver(mutations => {
            for (const m of mutations) {
                const newValue = m.target.getAttribute(m.attributeName);

                if (m.oldValue === 'navbar-collapse' && newValue === 'navbar-collapse collapse show') {
                    // * A small delay before add event listener as the act of opening the mobile menu causes scrolling,
                    // * which may close the menu automatically if it detects scrolling too soon.
                    setTimeout(() => {
                        window.addEventListener("scroll", this.handleScrolling);
                    }, 100);
                } else {
                    window.removeEventListener('scroll', this.handleScrolling);
                }
            }
        });

        this.observer.observe(this.$refs.hamburgerMenu, {
            attributes: true,
            attributeOldValue : true,
            attributeFilter: ['class'],
        });
    }
}
</script>

<style>
    :root {
        --header-height: 57px;
        --header-height-stickied: 57px;
        --sidebar-width: 100%;
    }

    .page-cart {
        --sidebar-width: 300px;
    }

    @media (min-width: 992px) {
        :root {
            --header-height: 116px;
            --header-height-stickied: 71px;
            --sidebar-width: 260px;
        }

        .page-cart {
            --sidebar-width: 340px;
        }
    }

    html,
    body {
        height: 100%;
    }

    html {
        font-size: 16px; /** For rem: 19px isn't ideal to work with */
    }

    body {
        color: #444444;
        font-family: Raleway, Arial, Helvetica, sans-serif;
        font-size: 19px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    iframe {
        max-width: 100%;
    }

    a {
        color: #FF5047;
    }

    a:hover {
        color: #FF5047;
    }

    h1 {
        font-size: 1.75rem;
    }

    h2 {
        font-size: 1.6rem;
    }

    h3 {
        font-size: 1.45rem;
    }

    h4 {
        font-size: 1.25rem;
    }

    h5 {
        font-size: 1.125rem;
    }

    p {
        font-size: 1.125rem;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    input[type="checkbox"].large {
        zoom: 1.5;
    }

    .header {
        background: #fff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    .header .navbar {
        align-items: center;
    }

    .navbar {
        width: 100%;
        background: #fff !important;
        align-items: flex-start;
        justify-content: space-between;
        padding: 5px 1rem;
    }

    .navbar-nav {
        align-items: center;
        margin: 0.5rem 0 0;
        -webkit-transform: none;
        transform: none;
        transition: transform 0.175s linear;
    }

    .navbar-nav li {
        padding: 0.25rem 0;
    }

    .navbar-nav a {
        color:#444;
        font-weight: 600;
        padding: 0.375rem 1rem;
    }

    .navbar-nav a:hover {
        text-decoration: none;
    }

    .navbar-brand {
        padding: 0;
        transition: max-width 0.175s;
    }

    .navbar-brand .logo {
        max-width: 66px;
    }

    .sidebar {
        background: #fff;
        border-right: 1px solid rgba(0, 0, 0, 0.125);
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    .sidebar .content {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .sidebar-sticky {
        float: left;
        width: var(--sidebar-width);
        position: sticky;
        top: calc(var(--header-height-stickied) - 1px);
        z-index: 1019;
    }

    /* Scrollbar */
    .sidebar ::-webkit-scrollbar-track,
    .sidebar-fixed ::-webkit-scrollbar-track
    {
        border-radius: 10px;
        box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.33);
        background-color: #F5F5F5;
    }

    .sidebar ::-webkit-scrollbar,
    .sidebar-fixed ::-webkit-scrollbar
    {
        width: 6px;
        height: 6px;
        background-color: #F5F5F5;
    }

    .sidebar ::-webkit-scrollbar-thumb,
    .sidebar-fixed ::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
        background-color: #888;
    }

    @media (min-width: 360px) {
        .navbar-brand .logo {
            max-width: 80px;
        }
    }

    @media (min-width: 768px) {
        h1 {
            font-size: 2.5rem;
        }

        h2 {
            font-size: 2rem;
        }

        h3 {
            font-size: 1.75rem;
        }

        h4 {
            font-size: 1.5rem;
        }

        h5 {
            font-size: 1.25rem;
        }

        p {
            font-size: 1.1875rem;
        }

        .selling-point-overlap {
            margin-right: -1.6rem;
        }

        .page-cart .sidebar-fixed {
            position: fixed;
            width: var(--sidebar-width);
            height: calc(100vh - var(--header-height));
        }

        .page-cart .sidebar-fixed + .main {
            padding-left: var(--sidebar-width);
        }

        .sidebar-fixed + .main .content {
            padding-left: 2rem;
        }
    }

    @media (min-width: 992px) {
        .logo {
            margin-left:10px;
        }

        .navbar-expand-lg .navbar-collapse {
            display: block !important;
        }

        .navbar-nav {
            justify-content: flex-end;
            margin-top: 0;
        }

        .navbar-nav li {
            padding: 0;
        }

        .navbar-nav a {
            padding: 0.25rem 1.25rem;
            display: inline-block;
        }

        .navbar-brand .logo {
            max-width: 180px;
        }

        .header.stickied .navbar-brand .logo {
            max-width: 106px;
        }

        .sidebar .content {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .sidebar-fixed {
            position: fixed;
        }

        .sidebar-sticky {
            top: var(--header-height-stickied);
        }

        .sidebar-fixed,
        .sidebar-sticky {
            width: var(--sidebar-width);
            height: calc(100vh - var(--header-height));
        }

        .header.stickied + #main .sidebar-fixed,
        .header.stickied + #main .sidebar-sticky {
            height: calc(100vh - var(--header-height-stickied));
        }

        .sidebar-fixed + .main,
        .sidebar-sticky + .main {
            padding-left: var(--sidebar-width);
        }

        .sidebar-sticky + .main .content,
        .sidebar-fixed + .main .content {
            padding-left: 2rem;
        }
    }

    .page-checkout .selling-point-container-mobile,
    .page-cart .selling-point-container-mobile {
        display: none;
    }

    .header.stickied .selling-point-container-mobile,
    .header.stickied .selling-point-container {
        position: absolute;
        top: -9999px;
        -webkit-transform: translateY(-200%);
        transform: translateY(-200%);
        transition: transform 0.175s;
    }

    .content {
        padding: 1rem;
    }

    .btn-primary,
    .btn-primary.disabled,
    .btn-primary:disabled {
        background: #85C71E;
        border-color: #85C71E;
        cursor: not-allowed;
    }

    .show>.btn-primary.dropdown-toggle,
    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active:focus,
    .btn-primary:not(:disabled):not(.disabled):active:focus,
    .show>.btn-primary.dropdown-toggle:focus,
    .btn-primary:active,
    .btn-primary:focus,
    .btn-primary:hover {
        background: #6CAE05;
        border-color: #6CAE05;
        box-shadow: 0 0 0 0.2rem rgba(108,174,5,.5);
    }

    .btn-primary.disabled,
    .btn-primary:disabled {
        opacity: 0.25;
    }

    .btn-outline-primary {
        border-color: #85C71E;
        color: #85C71E;
    }

    .show>.btn-outline-primary.dropdown-toggle,
    .show>.btn-outline-primary.dropdown-toggle:focus,
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-primary:active,
    .btn-outline-primary:focus,
    .btn-outline-primary:hover {
        background: #85C71E;
        border-color: #85C71E;
        color: #fff;
        box-shadow: none;
    }

    .form-control:focus {
        border-color: #85C71E;
        box-shadow: 0 0 0 0.125rem rgba(108,174,5,.5);
    }

    .clickable :hover {
        cursor: pointer;
    }

    .faded {
        opacity:0.5;
    }

    .selling-point-container {
        display: none;
    }

    .selling-point {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selling-point-overlap {
        margin-right: -1.125rem;
    }

    @media (min-width: 400px) {
        .selling-point-overlap {
            margin-right: -1.4rem;
        }
    }

    @media (min-width: 768px) {
        .selling-point-overlap {
            margin-right: -1.75rem;
        }
    }

    @media (min-width: 900px) and (max-width: 992px) {
        .selling-point-overlap {
            margin-right: -3.65rem;
        }
    }

    /* For 3 icon mobile header */
    @media (max-width: 767px) {
        .selling-point-offset {
            margin-left: -3rem; /*has to be double of the above margin right */
        }
    }

    /* Hide 2 icon, show 3 button above 450px */
    @media (min-width: 451px) {
        .hide-if-lg {
            display:none;
        }
    }

    /* For 2 icon mobile header */
    @media (max-width: 450px) {
        .selling-point-offset {
            margin-left: -1.4rem; /*has to be double of the above margin right */
        }

       .hide-if-sm {
           display:none;
       }
    }

    .selling-point p {
        font-size: 0.675rem;
        margin-bottom: 0;
        font-weight: 600;
        text-align: center;
    }

    .selling-point-subtext {
        font-size: 70%;
        display: block;
    }

    @media (min-width: 992px) {
        .selling-point-container {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            max-width: 800px;
            margin-left: auto;
            margin-top: 0.5rem;
        }

        .selling-point {
            flex-direction: row;
        }

        .selling-point img {
            margin-bottom: 0;
        }
    }

    @media (min-width: 1366px) {
        .selling-point-container {
            max-width: 940px;
        }

        .selling-point-overlap {
            margin-right: -2rem;
        }

        .selling-point p {
            font-size: 1rem;
        }
    }

    .product-search ::-webkit-scrollbar-track {
        border-radius: 10px;
        box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.33);
        background-color: #F5F5F5;
    }

    .product-search ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #F5F5F5;
    }

    .product-search ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
        background-color: #888;
    }

    .modal-mask {
        z-index: 1021;
    }

    .modal-container {
        color: #444444;
        font-family: Raleway, Arial, Helvetica, sans-serif;
        font-size: 19px;
    }

    .modal-container p {
        font-size: 16px;
    }

    .menuCart {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .menuCart a {
        display: inline-block;
        padding: 0;
        position: relative;
        background: url('/images/icons/shopping-cart.svg') center center no-repeat;
        background-size: contain;
        height: 28px;
        width: 50px;
    }

    .menuCart:hover a {
        background: url('/images/icons/shopping-cart-solid.svg') center center no-repeat;
        background-size: contain;
    }

    .menuCartCount {
        background: #FF5047;
        color: #fff;
        font-family: sans-serif;
        font-size: 12px;
        line-height: 1;
        position: absolute;
        top: -3px;
        right: -6px;
        padding: 3px;
        border-radius: 100%;
        height: 22px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user-account-desktop {
        display: none;
        border-left: 1px solid rgba(0,0,0,.125);
    }

    .user-account span {
        color: #444;
        font-size: 13px;
        font-weight: 400;
    }

    .user-account a {
        padding-right: 0.75rem;
    }

    .user-account .dropdown-menu {
        margin-top: 0;
    }

    @media (max-width: 767px) {
        .dropdown-menu {
            overflow-y: auto;
            max-height: 60vh;
        }
    }

    .user-account .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.155em;
        vertical-align: 0.05em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        color: #444;
    }

    .user-account .dropdown-item {
        font-size: 16px;
        font-weight: 400;
    }

    .user-account .icon-solid,
    .user-account:hover .icon,
    .user-account:active .icon,
    .user-account:focus .icon {
        display: none;
    }

    .user-account:hover .icon-solid,
    .user-account:active .icon-solid,
    .user-account:focus .icon-solid {
        display: initial;
    }

    .user-account-name {
        display: none;
    }

    @media (min-width: 768px) {
        .user-account-name {
            display: initial;
        }
    }

    @media (min-width: 992px) {
        .user-account-desktop {
            display: initial;
        }
    }

    @media (min-width: 1400px) {
        .container-xl {
            max-width: 1320px;
        }
    }

    .dropdown-menu .dropdown-item {
        display: block;
    }

    .spin {
        -webkit-animation: fa-spin 2s linear infinite;
        animation: fa-spin 2s linear infinite;
    }

    @keyframes fa-spin {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }

    select:disabled,
    input:disabled {
        cursor: not-allowed;
    }

    .whitespace-nowrap {
        white-space: nowrap;
    }
</style>
